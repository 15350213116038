import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import {
  getDataFromLocalStorageWithExpire,
  mobileResolution, smallTabletResolution,
  tabletResolution,
} from 'utils/helper';
import {
  desktopResolutionSet,
  mobileResolutionSet,
  smallTabletResolutionSet,
  tabletResolutionSet,
} from 'redux/reducers/layout';
import { SUBSCRIPTION_CASH_KEY, useSubscribeMutation } from 'redux/apis/dataSending';
import { AppContext } from 'utils/appContext';
import { routes } from 'utils/routes';
import { PAGES_WITHOUT_INDEXING } from 'utils/constants';

export const useLayout = ({ children, introSection }) => {
  const { pathname } = useRouter();
  const isHomePage = pathname === routes.homepage.path;
  const withoutIndexing = PAGES_WITHOUT_INDEXING.includes(pathname);
  const { contextData: { duck } } = useContext(AppContext);
  const isDuckLoading = isHomePage && !duck;

  const dispatch = useDispatch();

  useEffect(() => {
    const handleOnResize = () => {
      const { innerWidth } = window;

      if (innerWidth <= mobileResolution) {
        dispatch(mobileResolutionSet());
      } else if (innerWidth > mobileResolution && innerWidth <= smallTabletResolution) {
        dispatch(smallTabletResolutionSet());
      } else if (innerWidth > smallTabletResolution && innerWidth <= tabletResolution) {
        dispatch(tabletResolutionSet());
      } else {
        dispatch(desktopResolutionSet());
      }
    };

    handleOnResize();
    window.addEventListener('resize', handleOnResize);

    return () => window.removeEventListener('resize', handleOnResize);
  }, [dispatch]);

  const [subscribe] = useSubscribeMutation({ fixedCacheKey: SUBSCRIPTION_CASH_KEY });

  useEffect(() => {
    subscribe({ savedSubscriptionEmail: getDataFromLocalStorageWithExpire('subscriptionEmail') });
  }, [subscribe, children]);

  return {
    children,
    introSection,
    isDuckLoading,
    withoutIndexing,
  };
};
